import React from 'react';
import Layout from '../components/layout';
import Nav from '../components/nav';
import MobileNav from '../components/mobileNav';
import SEO from '../components/seo';
import Footer from '../components/footer';

import firebase from 'firebase';

import './contact.scss';
export default class Contact extends React.PureComponent {
  componentDidMount() {
    var firebaseConfig = {
      apiKey: "AIzaSyD-CZ8TEI3rZvThfgxqDDtRfdEwCoAF5ao",
      authDomain: "portfolio-624f4.firebaseapp.com",
      databaseURL: "https://portfolio-624f4.firebaseio.com",
      projectId: "portfolio-624f4",
      storageBucket: "portfolio-624f4.appspot.com",
      messagingSenderId: "678835437344",
      appId: "1:678835437344:web:a3527739d7da23a3"
    };

    firebase.initializeApp(firebaseConfig);
  }

  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }

  /** Handles name input changes */
  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  }

  /** Handles email input changes */
  onEmailChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  }

  /** Handles message input changes */
  onMessageChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  }

  // // Submit form
  // submitForm = (e) => {
  //   e.preventDefault();

  //   // Get values
  //   var name = getInputVal('name');
  //   var email = getInputVal('email');
  //   var message = getInputVal('message');

  //   // Save message
  //   saveMessage(name, email, message);

  //   // Show alert
  //   document.querySelector('.alert').style.display = 'block';

  //   // Hide alert after 3 seconds
  //   setTimeout(function(){
  //     document.querySelector('.alert').style.display = 'none';
  //   },3000);

  //   // Clear form
  //   document.getElementById('contactForm').reset();
  // }

  // // Function to get get form values
  // getInputVal = (id) => {
  //   return document.getElementById(id).value;
  // }

  // // Save message to firebase
  // saveMessage = (name, email, message) => {
  //   var newMessageRef = messagesRef.push();
  //   newMessageRef.set({
  //     name: name,
  //     email: email,
  //     message: message
  //   });
  // }

  render() {
    const { name, email, message } = this.state;
    return (
      <Layout>
        <SEO title='Contact' description='Contact Robert Lester'/>
        <Nav />
        <MobileNav />
        <div className='contact__section'>
          <div className='contact__form'>
            <h1 className="contact__title">Contact</h1>
            <div className='inner'>
              <form name='contactForm' id='contactForm'>
              <div className='field'>
                <label>Name</label>
                <input
                  type='text'
                  name='name'
                  id='name'
                  value={name}
                  onChange={this.handleNameChange}
                />
              </div>
              <div className='field'>
                <label>Email</label>
                <input
                  type='text'
                  name='email'
                  id='email'
                  value={email}
                  onChange={this.handleEmailChange}
                />
              </div>
              <div className='field'>
                <label>Message</label>
                <textarea
                  name='message'
                  rows='6'
                  value={message}
                  onChange={this.handleMessageChange}
                >
                </textarea>
              </div>
              <div className='submit'>
                <button type='submit' className='btn__med' id='message' value={message}>Send</button>
              </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    );
  }
}